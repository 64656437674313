import { Text } from '@truepill/react-capsule'
import { defaultTheme } from '@vpharm-platform/shared'
import React from 'react'

import { PrescriptionStatusType } from '../../common/helpers/prescriptions'
import { useContentfulTheme, useCustomerProfile } from '../../hooks'
import PrescriptionCardIcon from '../../pages/PrescriptionManagement/PrescriptionCard/PrescriptionCardIcon'
import PrescriptionPrice from '../../pages/PrescriptionManagement/PrescriptionCard/PrescriptionPrice'
import { StyledPrescriptionCard } from '../../pages/PrescriptionManagement/PrescriptionCard/styledComponents'
import { OrderMode } from '../../persistRecoil'
import { LinkButton } from '../LinkButton'
import { CopayTriageMessageMicrocopy, PrescriptionCardMicrocopy } from './microcopy'

export interface MedicationCardProps {
  checkoutPrice: number | undefined
  daysSupply?: string
  form: string | null
  isLargerThanTablet: boolean
  isSavingsCardApplied: boolean
  handleClickSavingsCardApplied?: () => void
  medicationBrandName: string | null
  medicationGenericName: string | null
  orderMode: OrderMode
  potentialPriceWithSavingsCard: number | null
  refillsRemaining: number
  retailPrice: number | null
  rxNumber: string
  statusType: PrescriptionStatusType
  insurancePrice: number | null
  totalPriceAfterSavings: number | null
  totalSavings: number | null
  handleRemoveItemFromCart?: () => void
  copayTriageMessage?: CopayTriageMessageMicrocopy
  content?: PrescriptionCardMicrocopy
  isAutoRefillEligible?: boolean
}

const MedicationCard = ({
  checkoutPrice,
  daysSupply,
  form,
  isSavingsCardApplied,
  medicationBrandName,
  medicationGenericName,
  orderMode,
  potentialPriceWithSavingsCard,
  refillsRemaining,
  retailPrice,
  rxNumber,
  statusType,
  insurancePrice,
  totalPriceAfterSavings,
  handleClickSavingsCardApplied,
  handleRemoveItemFromCart,
  totalSavings,
  content,
  isAutoRefillEligible,
}: MedicationCardProps): React.ReactElement => {
  const { customerProfile } = useCustomerProfile()
  const { theme } = useContentfulTheme()
  const allowPriceTransparency = customerProfile.allowPriceTransparency
  const retailPriceLineThrough =
    (insurancePrice && insurancePrice > 0) || (totalPriceAfterSavings && totalPriceAfterSavings > 0) ? 'line-through' : 'none'
  const insuranceLineThrough = insurancePrice && insurancePrice > 0 && totalPriceAfterSavings && totalPriceAfterSavings > 0 ? 'line-through' : 'none'
  const mapStatusTypeToStatusText = (): React.ReactElement | null => {
    switch (statusType) {
      case PrescriptionStatusType.ORDERABLE:
      case PrescriptionStatusType.ORDERABLE_WITH_WARNING:
        if (checkoutPrice !== undefined) {
          return (
            <PrescriptionPrice
              checkoutPrice={checkoutPrice}
              orderMode={orderMode}
              retailPrice={retailPrice}
              savingsCardPrice={potentialPriceWithSavingsCard}
              allowPriceTransparency={allowPriceTransparency}
            />
          )
        }
        break
      case PrescriptionStatusType.PENDING:
        return <StyledPrescriptionCard.StyledHeader priceTransparencyFlag={allowPriceTransparency}>Pending</StyledPrescriptionCard.StyledHeader>
      default:
    }
    return null
  }

  const SavingsCardAppliedChip = (): React.ReactElement => (
    <StyledPrescriptionCard.ClickableChipWrapper
      onClick={handleClickSavingsCardApplied}
      pointerOnHover={!!handleClickSavingsCardApplied}
      role={'button'}
      aria-label='Savings Card Applied - View/Edit savings card'
    >
      <StyledPrescriptionCard.SavingsCardChip
        state='success'
        color={theme.colors.rxmSavingsCardChipLabel ?? theme.colors['functional-success-dark'] ?? defaultTheme.colors['functional-success-dark']}
        background={
          theme.colors.rxmSavingsCardChipBackground ?? theme.colors['functional-success-light'] ?? defaultTheme.colors['functional-success-light']
        }
      >
        <StyledPrescriptionCard.PreventLineBreak>{content?.savingsChipText}</StyledPrescriptionCard.PreventLineBreak>
      </StyledPrescriptionCard.SavingsCardChip>
    </StyledPrescriptionCard.ClickableChipWrapper>
  )

  return (
    <StyledPrescriptionCard.PrescriptionHeaderContainer data-testid='prescription-card-header' borderRadius={!isAutoRefillEligible} vpTheme={theme}>
      <StyledPrescriptionCard.PrescriptionNameContainer vpTheme={theme}>
        <StyledPrescriptionCard.PrescriptionName>
          <StyledPrescriptionCard.PrescriptionDetails data-testid='rxnumber-refills-daysupply'>
            <StyledPrescriptionCard.SpanWholeLineInMobile>
              <StyledPrescriptionCard.PreventLineBreak>Rx #{rxNumber}</StyledPrescriptionCard.PreventLineBreak>
            </StyledPrescriptionCard.SpanWholeLineInMobile>
            <div>
              <StyledPrescriptionCard.StyledSpan>
                <StyledPrescriptionCard.PreventLineBreak>
                  {refillsRemaining} {refillsRemaining === 1 ? 'refill' : 'refills'} left
                </StyledPrescriptionCard.PreventLineBreak>
              </StyledPrescriptionCard.StyledSpan>
              {daysSupply && (
                <StyledPrescriptionCard.StyledSpan>
                  <StyledPrescriptionCard.PreventLineBreak>{daysSupply} day supply</StyledPrescriptionCard.PreventLineBreak>
                </StyledPrescriptionCard.StyledSpan>
              )}
            </div>
          </StyledPrescriptionCard.PrescriptionDetails>
          {medicationBrandName && <StyledPrescriptionCard.StyledHeaderBrandName>{medicationBrandName}</StyledPrescriptionCard.StyledHeaderBrandName>}
          {medicationGenericName && medicationBrandName !== medicationGenericName && <Text>{`Generic: ${medicationGenericName}`}</Text>}
        </StyledPrescriptionCard.PrescriptionName>
        {handleRemoveItemFromCart && (
          <StyledPrescriptionCard.RemoveContainerDesktop>
            <LinkButton onClick={handleRemoveItemFromCart} dataTestId='remove-link-desktop'>
              Remove
            </LinkButton>
          </StyledPrescriptionCard.RemoveContainerDesktop>
        )}
      </StyledPrescriptionCard.PrescriptionNameContainer>
      <StyledPrescriptionCard.PricingInfo>
        <StyledPrescriptionCard.PricingInfoHeader>
          <StyledPrescriptionCard.PrescriptionImageContainer>
            <StyledPrescriptionCard.PrescriptionImage>
              <PrescriptionCardIcon form={form} />
            </StyledPrescriptionCard.PrescriptionImage>
          </StyledPrescriptionCard.PrescriptionImageContainer>
          <StyledPrescriptionCard.PrescriptionStatusText vpTheme={theme}>
            {(isSavingsCardApplied || totalPriceAfterSavings !== null) && <SavingsCardAppliedChip />}
            {mapStatusTypeToStatusText()}
          </StyledPrescriptionCard.PrescriptionStatusText>
        </StyledPrescriptionCard.PricingInfoHeader>
        {allowPriceTransparency && (statusType === PrescriptionStatusType.ORDERABLE || statusType === PrescriptionStatusType.ORDERABLE_WITH_WARNING) && (
          <StyledPrescriptionCard.PriceBreakdown>
            {!!retailPrice && (!!insurancePrice || !!totalPriceAfterSavings) && (
              <StyledPrescriptionCard.PriceBreakdownLine>
                <Text
                  style={{ fontSize: '0.875rem', color: `${theme.colors['typography-medium'] ?? defaultTheme.colors['typography-medium']}` }}
                  variant='body-sm'
                >
                  {content?.retailPrice}
                </Text>
                <Text
                  style={{
                    fontSize: '0.875rem',
                    color: `${theme.colors['typography-dark'] && theme.colors['typography-dark']}`,
                    textDecoration: `${retailPriceLineThrough}`,
                  }}
                  variant='body-sm'
                >
                  ${retailPrice.toFixed(2)}
                </Text>
              </StyledPrescriptionCard.PriceBreakdownLine>
            )}
            {!!insurancePrice && insurancePrice !== 0 && (
              <StyledPrescriptionCard.PriceBreakdownLine>
                <Text
                  style={{ fontSize: '0.875rem', color: `${theme.colors['typography-medium'] ?? defaultTheme.colors['typography-medium']}}` }}
                  variant='body-sm'
                >
                  {content?.priceAfterInsurance}
                </Text>
                <Text
                  style={{
                    fontSize: '14px',
                    color: `${theme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']}`,
                    textDecoration: `${insuranceLineThrough}`,
                  }}
                  variant='body-sm'
                >
                  ${insurancePrice.toFixed(2)}
                </Text>
              </StyledPrescriptionCard.PriceBreakdownLine>
            )}

            {!!totalPriceAfterSavings && (
              <StyledPrescriptionCard.PriceBreakdownLine>
                <Text
                  style={{ fontSize: '14px', color: `${theme.colors['typography-medium'] ?? defaultTheme.colors['typography-medium']}}` }}
                  variant='body-sm'
                >
                  {content?.priceAfterSavings}
                </Text>
                <Text
                  style={{ fontSize: '14px', color: `${theme.colors['typography-dark'] ?? defaultTheme.colors['typography-dark']}` }}
                  variant='body-sm'
                >
                  ${totalPriceAfterSavings.toFixed(2)}
                </Text>
              </StyledPrescriptionCard.PriceBreakdownLine>
            )}

            {!!totalSavings && (
              <StyledPrescriptionCard.PriceBreakdownLine>
                <Text
                  style={{
                    fontSize: '14px',
                    color: `${theme.colors['functional-success-dark'] ?? defaultTheme.colors['functional-success-dark']}}`,
                  }}
                  variant='body-sm'
                  aria-label='total savings'
                >
                  {content?.totalSavingsText || 'Total savings'}
                </Text>
                <Text
                  style={{
                    fontSize: '14px',
                    color: `${theme.colors['functional-success-dark'] ?? defaultTheme.colors['functional-success-dark']}`,
                  }}
                  variant='body-sm'
                >
                  ${totalSavings.toFixed(2)}
                </Text>
              </StyledPrescriptionCard.PriceBreakdownLine>
            )}
          </StyledPrescriptionCard.PriceBreakdown>
        )}
      </StyledPrescriptionCard.PricingInfo>
      {handleRemoveItemFromCart && (
        <StyledPrescriptionCard.RemoveContainerMobile>
          <LinkButton onClick={handleRemoveItemFromCart} dataTestId='remove-link-mobile'>
            Remove
          </LinkButton>
        </StyledPrescriptionCard.RemoveContainerMobile>
      )}
    </StyledPrescriptionCard.PrescriptionHeaderContainer>
  )
}

export default MedicationCard
