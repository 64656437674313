export const VP1192_DISPLAY_HOMEPAGE = 'vp1192DisplayHomepage'
export const DISPLAY_MAINTENANCE_PAGE = 'displayMaintenancePage'
export const SEPARATE_URAC_SURVEY_PREGNANCY_FIELD = 'separateUracSurveyPregnancyField'
export const DISPLAY_COPAY_TRIAGE_REJECTION_REASONS = 'displayCopayTriageRejectionReasons'
export const DISPLAY_ORDER_TRIAGE_REJECTION_REASONS = 'displayOrderTriageRejectionReasons'
export const SIGN_UP_VALIDATE_ON_SUBMIT = 'signUpValidateOnSubmit'
export const AUTO_REFILLS = 'autoRefills'
export const DISPLAY_INSURANCE_SWAP = 'sendInsuranceCardType'
export const ENABLE_STOCK_NOTIFICATIONS = 'enableStockNotifications'
export const TRANSFER_IN_PAGE_ENHANCEMENTS = 'transferInPageEnhancements'
export const VP_5632_2_DAY_SHIPPING = 'vp56322DayShipping'
export const MIXED_CART = 'mixedCart'
export const VP_5756_DISPLAY_APPLE_PAY = 'vp5756DisplayApplePay'
