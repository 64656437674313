import { Spacer, Text } from '@truepill/react-capsule'
import { VpTheme } from '@vpharm-platform/shared'
import { ReactElement } from 'react'

import { PatientInsurance } from '../../interfaces'
import { ButtonCard } from '../Fragments/ButtonCard'
import { ItemCard, ItemCardWrapper } from '../Fragments/ItemCard'
import { StyledPlusCircle } from './styledComponents'

interface Props {
  theme: VpTheme
  patientInsuranceList?: PatientInsurance[]
  handleClickAddInsurance: () => void
  handleClickEditInsurance: (insurance: PatientInsurance) => void
  handleClickDeleteInsurance: (insurance: PatientInsurance) => void
}

const SavedInsurances: React.FC<Props> = ({
  theme,
  patientInsuranceList,
  handleClickAddInsurance,
  handleClickEditInsurance,
  handleClickDeleteInsurance,
}): ReactElement => {
  return (
    <>
      <ItemCardWrapper mobileBreakpoint='1100px'>
        {patientInsuranceList ? (
          patientInsuranceList.map((insurance) => (
            <div key={`insurance-${insurance.truepill_insurance_token}`}>
              <ItemCard
                itemSubject='insurance'
                heading={`Cardholder Id: ${insurance.cardholder_id}`}
                line1={<Text>{`Rx BIN: ${insurance.rx_bin}`}</Text>}
                line2={
                  insurance?.pcn ? (
                    <Text>Rx PCN: {insurance.pcn}</Text>
                  ) : insurance?.rx_group ? (
                    <Text>Rx Group: {insurance.rx_group}</Text>
                  ) : (
                    <Spacer size='lg' />
                  )
                }
                line3={insurance.pcn && insurance.rx_group ? <Text>Rx Group: {insurance.rx_group}</Text> : <Spacer size='lg' />}
                editButtonText='Edit details'
                deleteButtonText='Delete'
                editHandler={() => handleClickEditInsurance(insurance)}
                deleteHandler={() => handleClickDeleteInsurance(insurance)}
              />
            </div>
          ))
        ) : (
          <></>
        )}
        {patientInsuranceList && patientInsuranceList?.length < 2 && (
          <ButtonCard
            text='Add New Insurance'
            icon={<StyledPlusCircle vpTheme={theme} className='w-md text-cap-info-dark mb-2xs inline-block' />}
            ariaLabel='add new insurance button'
            onClick={() => handleClickAddInsurance()}
          />
        )}
      </ItemCardWrapper>
    </>
  )
}

export default SavedInsurances
