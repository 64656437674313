import { Header, SEO, Spacer, Text } from '@truepill/react-capsule'
import LoadingAnimation from 'Components/LoadingAnimation'
import { AccountLayout } from 'Components/PageLayoutWithSidebar'
import { usePatientInsurance } from 'hooks/usePatientInsurance'
import { PatientInsurance, PatientInsuranceSubmitPayload } from 'interfaces'
import React, { useEffect, useState } from 'react'

import { useCustomerConfigContext } from '../../../Components/CustomerConfigProvider'
import CustomerCopyChange from '../../../Components/CustomerCopyChange'
import { withEmailVerified } from '../../../Components/Hoc/withEmailVerified'
import DeleteInsurance from '../../../Components/Insurances/DeleteInsurance'
import InsuranceForm from '../../../Components/Insurances/InsuranceForm/InsuranceForm'
import SavedInsurances from '../../../Components/Insurances/SavedInsurances'
import SupportLink from '../../../Components/SupportLink'
import ToastMessage from '../../../Components/ToastMessage'
import { AccountPage } from '../../../constants'
import { useAnalytics } from '../../../hooks/analytics-context'
import { InsurancePageMicrocopy } from '../../../hooks/contentful/types/microcopy'
import { useGetPageContent } from '../../../hooks/contentful/useGetPageContent'
import Container from '../Container'
import StyledHeader from '../StyledHeader'
import InsuranceBanner from './InsuranceBanner'
import { DeleteInsuranceModal, InsuranceFormModal } from './styledComponents'

interface IToastMessage {
  message: string
  type: 'error' | 'success'
  displayCustomerSupport?: boolean
}

const InsurancePageContent: React.FunctionComponent = () => {
  const [toastMessage, setToastMessage] = useState<IToastMessage>()
  const [insuranceFormModalOpen, setInsuranceFormModalOpen] = useState(false)
  const [deleteInsuranceModalOpen, setDeleteInsuranceModalOpen] = useState(false)
  const [currentPatientInsurance, setCurrentPatientInsurance] = useState<PatientInsurance | null>(null)
  const { trackButtonClickEvent } = useAnalytics()
  const { isErrorLoading, isLoadingInsurance, isDeletingInsurance, patientInsuranceList, deletePatientInsurance, submitPatientInsurance } =
    usePatientInsurance()
  const { pharmCustomer } = useCustomerConfigContext()
  const { loading, error, content, seo, theme } = useGetPageContent<InsurancePageMicrocopy>('insurancePage')

  useEffect(() => {
    if (error || isErrorLoading) {
      setToastMessage({
        type: 'error',
        message: `We couldn't load your insurance details`,
      })
    }
  }, [error, isErrorLoading])

  const handleClickAddInsurance = () => {
    setCurrentPatientInsurance(null)
    setInsuranceFormModalOpen(true)
    trackButtonClickEvent('open_add_new_insurance_modal', 'Add New Insurance', 'opens new insurance modal')
  }

  const handleClickEditInsurance = (insurance: PatientInsurance) => {
    setCurrentPatientInsurance(insurance)
    setInsuranceFormModalOpen(true)
    trackButtonClickEvent('open_edit_insurance_modal', 'Edit details', 'opens edit insurance modal')
  }

  const handleClickDeleteInsurance = (insurance: PatientInsurance) => {
    setCurrentPatientInsurance(insurance)
    setDeleteInsuranceModalOpen(true)
    trackButtonClickEvent('open_delete_insurance', 'delete', 'opens delete insurance modal')
  }

  const handleSubmitPatientInsurance = async (values: PatientInsuranceSubmitPayload) => {
    const keys: (keyof Omit<PatientInsuranceSubmitPayload, 'insurance_image_name'>)[] = [
      'cardholder_id',
      'rx_bin',
      'rx_group',
      'pcn',
      'relationship_to_primary_cardholder',
    ]
    const modifiedFields = keys.filter((key) => {
      const previousValue = currentPatientInsurance ? currentPatientInsurance[key] : null
      return (values[key] && previousValue !== values[key]) || !!values[key] !== !!previousValue
    })

    const isInsuranceImageModified = !!values.insurance_image_name || values.insurance_image_name === null

    // Always call the endpoint if insurance image name is a provided value, or if any fields were modified in the insurance
    if (modifiedFields.length || isInsuranceImageModified) {
      trackButtonClickEvent('save-or-update-patient-insurance', 'Save', currentPatientInsurance ? 'Update insurance' : 'Add new Insurance')
      const isSuccess = await submitPatientInsurance(values, currentPatientInsurance?.truepill_insurance_token as string | undefined)
      if (isSuccess) {
        setToastMessage({
          type: 'success',
          message: 'Your pharmacy insurance has been successfully added',
        })
      } else {
        setToastMessage({
          type: 'error',
          message: 'Something went wrong when trying to update your insurance details. Please try again later',
          displayCustomerSupport: true,
        })
      }
    }

    setInsuranceFormModalOpen(false)
  }

  const handleDeletePatientInsurance = async (insurance: PatientInsurance) => {
    const isSuccess = await deletePatientInsurance(insurance.truepill_insurance_token as string)
    if (isSuccess) {
      setToastMessage({
        type: 'success',
        message: 'Your pharmacy insurance has been successfully deleted',
      })
    } else {
      setToastMessage({
        type: 'error',
        message: 'Oops, something went wrong. Please try again',
        displayCustomerSupport: true,
      })
    }
    setDeleteInsuranceModalOpen(false)
    trackButtonClickEvent('confirm_delete_insurance', 'confirm delete', 'deletes insurance')
  }

  if (isLoadingInsurance || loading) {
    return <LoadingAnimation />
  }

  return (
    <AccountLayout selected={AccountPage.Insurance}>
      <SEO title={seo?.fields.title || `${pharmCustomer?.displayName} - Account Management`} useDefaults />
      <Container>
        <StyledHeader>
          <Header as='h1' variant='3xl'>
            Insurance Management
          </Header>
          <Spacer size='xl' />
          <Text>
            To pay for prescriptions using insurance, use the links below to add your insurance details to your account. You have the option to add
            supplemental insurance or copay coverage in addition to your primary insurance. Savings cards and discount cards can be added directly to
            eligible medications listed on the Prescription Manager.
          </Text>
        </StyledHeader>
        <Spacer size='xl' />
        <SavedInsurances
          theme={theme}
          patientInsuranceList={patientInsuranceList}
          handleClickAddInsurance={handleClickAddInsurance}
          handleClickEditInsurance={handleClickEditInsurance}
          handleClickDeleteInsurance={handleClickDeleteInsurance}
        />
        {content?.insuranceBannerMainHeader && (
          <InsuranceBanner
            theme={theme}
            iconUrl={content?.insuranceBannerIconUrl}
            mainHeader={content?.insuranceBannerMainHeader}
            backgroundColor={content?.insuranceBannerBackgroundColor}
            subtext={content?.insuranceBannerSubtext}
          />
        )}
        <InsuranceFormModal
          isOpen={insuranceFormModalOpen}
          onDismiss={() => setInsuranceFormModalOpen(false)}
          aria-label={currentPatientInsurance ? 'edit insurance modal' : 'add new insurance modal'}
        >
          <InsuranceForm
            theme={theme}
            pageContent={content}
            currentInsurance={currentPatientInsurance}
            handleSubmitForm={handleSubmitPatientInsurance}
            handleCancelForm={() => {
              setInsuranceFormModalOpen(false)
              trackButtonClickEvent('cancel_add_insurance', 'cancel', 'cancels add insurance')
            }}
          />
        </InsuranceFormModal>
        <DeleteInsuranceModal
          isOpen={deleteInsuranceModalOpen}
          onDismiss={() => {
            setDeleteInsuranceModalOpen(false)
            trackButtonClickEvent('close_delete_insurance_modal', 'x', 'closes delete insurance modal')
          }}
          aria-label='delete insurance modal'
        >
          <DeleteInsurance
            theme={theme}
            currentInsurance={currentPatientInsurance as PatientInsurance}
            isDeletingInsurance={isDeletingInsurance}
            cancel={() => {
              setDeleteInsuranceModalOpen(false)
              trackButtonClickEvent('cancel_delete_insurance', 'cancel delete', 'cancels delete insurance')
            }}
            handleDeleteInsurance={handleDeletePatientInsurance}
          />
        </DeleteInsuranceModal>
      </Container>
      <ToastMessage
        state={toastMessage?.type}
        visible={!!toastMessage}
        timeout={3000}
        onTimeout={() => setToastMessage(undefined)}
        onDismiss={() => setToastMessage(undefined)}
      >
        <Text>
          {toastMessage?.message}{' '}
          {toastMessage?.displayCustomerSupport ? (
            <>
              or <SupportLink>contact Patient Support</SupportLink>
            </>
          ) : null}
        </Text>
      </ToastMessage>
      {content?.customerCopyChangeText && <CustomerCopyChange copyChangeText={content.customerCopyChangeText} />}
    </AccountLayout>
  )
}

export default withEmailVerified(InsurancePageContent)
