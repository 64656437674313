import { Document } from '@contentful/rich-text-types'
import { Spacer, Text } from '@truepill/react-capsule'
import {
  CopayErrorCode,
  InsuranceInfo,
  MedicationInfo,
  MedicationInventory,
  PrescriptionCopay,
  PrescriptionCopayStatusType,
  PrescriptionDisplayStatus,
} from '@vpharm-platform/shared'
import moment, { utc } from 'moment'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { CopayRejectionMessageMicrocopy, CopayTriageMessageMicrocopy, PrescriptionCardMicrocopy } from '../../Components/MedicationCard/microcopy'
import { InsuranceSwapTag } from '../../pages/PrescriptionManagement/InsuranceSwapTag'
import { getFormattedDate } from '../../utils/dateUtilities'
import { replaceHandlebars } from '../../utils/stringUtilities'

export enum PrescriptionCopayRejectReason {
  REFILL_TOO_SOON = 'REFILL_TOO_SOON',
}

export type PrescriptionCardActions = 'requestPrice' | 'addToCart'

export enum PrescriptionStatusType {
  ORDERED = 'ORDERED',
  ORDERABLE = 'ORDERABLE',
  ORDERABLE_WITH_WARNING = 'ORDERABLE_WITH_WARNING',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  UNAVAILABLE = 'UNAVAILABLE',
}

interface PrescriptionDisplayInfo {
  statusTag: string
  statusMessage?: string
  statusType: PrescriptionStatusType
  actionable?: PrescriptionCardActions
  statusHtml?: React.ReactElement
  checkoutPrice?: number
  issue?: string
  issueDetails?: Document
  additionalDetails?: string
  mixedCardStatusTag?: string
  mixedCardStatusType?: PrescriptionStatusType
}

const errorStatusHtml = (
  <Fragment>
    <Text>
      We received an error when attempting to submit a claim using the insurance and/or savings card details you provided. If you have not yet entered
      a savings card, you can do so now (click “Apply Now” above) to see how this affects your claim (only applicable on certain medications).
    </Text>
    <Spacer size='md' />
    <Text>
      Alternatively, you can purchase at the cash retail price by removing your insurance and/or savings card. To remove your insurance, visit the{' '}
      <Link style={{ color: 'inherit', textDecoration: 'none' }} to='/account/insurance'>
        Insurance page
      </Link>{' '}
      and click Delete. To remove your savings card, click the green “Savings” message above the price on this Rx, then click “Remove Card.”
    </Text>
    <Spacer size='md' />
    <Text>For more information or to learn what other options might be available, please contact our Patient Support team.</Text>
  </Fragment>
)

const getInsuranceSwapStatusHtml = (replacedInsurances: InsuranceInfo[] | undefined, replacedSavingsCard: InsuranceInfo[] | undefined) => (
  <InsuranceSwapTag insurances={replacedInsurances} savingCards={replacedSavingsCard} />
)

/*
  Statuses not included
  TRANSFER_IN_PROGRESS - Cannot occur, since Prescription won't show up in Prescription Manager until NotifyRx is received
  INITIALIZED - Becomes INSURANCE_UNAVAILABLE when # of patient insurances is 0
*/
export const parsePrescriptionStatus = (
  { prescriptionStatus, unitPrice, inventory, copay, nextRefillDate, replacedInsurances, replacedSavingsCard }: MedicationInfo,
  content?: PrescriptionCardMicrocopy,
  copayTriageMessage?: CopayTriageMessageMicrocopy | null,
  copayRejectionMessage?: CopayRejectionMessageMicrocopy | null,
  displayInsuranceSwap?: boolean,
): PrescriptionDisplayInfo => {
  switch (prescriptionStatus) {
    // ORDERED
    case PrescriptionDisplayStatus.FILL_IN_PROGRESS:
      return {
        statusMessage: 'This prescription is currently being filled.',
        statusTag: 'Being filled',
        mixedCardStatusTag: 'NOT READY',
        mixedCardStatusType: PrescriptionStatusType.PENDING,
        statusType: PrescriptionStatusType.ORDERED,
      }
    // ORDERABLE & ORDERABLE_WITH_WARNING
    case PrescriptionDisplayStatus.COPAY_PRICE_AVAILABLE:
      if (
        displayInsuranceSwap &&
        ((replacedInsurances?.length && replacedInsurances?.length > 0) || (replacedSavingsCard?.length && replacedSavingsCard?.length > 0))
      ) {
        return {
          actionable: 'addToCart',
          checkoutPrice: copay?.type === PrescriptionCopayStatusType.SUCCESS ? Number(copay.amount) : undefined,
          statusTag: 'Insurance details updated',
          mixedCardStatusTag: 'READY TO ORDER',
          mixedCardStatusType: PrescriptionStatusType.ORDERABLE,
          statusType: PrescriptionStatusType.ORDERABLE_WITH_WARNING,
          statusHtml: getInsuranceSwapStatusHtml(replacedInsurances, replacedSavingsCard),
        }
      }
      return {
        actionable: 'addToCart',
        checkoutPrice: copay?.type === PrescriptionCopayStatusType.SUCCESS ? Number(copay.amount) : undefined,
        statusMessage: 'This prescription is ready to fill.',
        mixedCardStatusTag: 'READY TO ORDER',
        statusTag: 'Ready to order',
        statusType: PrescriptionStatusType.ORDERABLE,
      }
    case PrescriptionDisplayStatus.CASH_ORDERABLE:
      return {
        actionable: 'addToCart',
        checkoutPrice: Number(unitPrice),
        statusMessage: 'This prescription is ready for checkout',
        statusTag: 'Ready to order',
        mixedCardStatusTag: 'READY TO ORDER',
        statusType: PrescriptionStatusType.ORDERABLE,
      }
    // PENDING
    case PrescriptionDisplayStatus.PENDING_REFILL: {
      if (copay?.type === PrescriptionCopayStatusType.REJECTED) {
        return {
          statusMessage: `This prescription was recently filled. You can re-order on ${getFormattedDate(nextRefillDate)}.`,
          mixedCardStatusTag: 'NOT READY',
          statusTag: 'Refill too soon',
          statusType: PrescriptionStatusType.PENDING,
        }
      }
      return {
        statusTag: 'Recently filled',
        mixedCardStatusTag: 'NOT READY',
        statusMessage: 'This prescription was recently filled.',
        statusType: PrescriptionStatusType.PENDING,
      }
    }
    case PrescriptionDisplayStatus.COPAY_IN_PROGRESS:
      return {
        statusMessage: 'This prescription will be ready to order soon. We are currently calculating the price.',
        statusTag: 'Available soon',
        mixedCardStatusTag: 'NOT READY',
        statusType: PrescriptionStatusType.PENDING,
      }
    case PrescriptionDisplayStatus.PA_IN_PROGRESS:
      if (copay?.type === PrescriptionCopayStatusType.TRIAGED && copayTriageMessage) {
        switch (copay?.error_code) {
          case 'CT003':
            return {
              statusMessage: copayTriageMessage?.priorAuthorizationNeeded?.message ?? '',
              statusTag: 'Copay Price Pending',
              mixedCardStatusTag: 'NOT READY',
              statusType: PrescriptionStatusType.PENDING,
              issue: copayTriageMessage?.priorAuthorizationNeeded?.displayDescription ? 'Prior Authorization Needed' : '',
              issueDetails: copayTriageMessage?.priorAuthorizationNeeded?.details,
              additionalDetails: copayTriageMessage?.priorAuthorizationNeeded?.displayAdditionalInformation ? copay?.description || '' : '',
            }
        }
        return {
          statusMessage:
            'Your insurance has requested a prior authorization for this prescription, which is underway. If you have any questions, please contact our Customer Support team.',
          statusTag: 'Prior authorization required',
          mixedCardStatusTag: 'NOT READY',
          statusType: PrescriptionStatusType.PENDING,
        }
      }
      return {
        statusMessage:
          'Your insurance has requested a prior authorization for this prescription, which is underway. If you have any questions, please contact our Customer Support team.',
        statusTag: 'Prior authorization required',
        mixedCardStatusTag: 'NOT READY',
        statusType: PrescriptionStatusType.PENDING,
      }

    case PrescriptionDisplayStatus.TRANSFER_OUT_IN_PROGRESS:
      return {
        statusMessage: 'This prescription is being transferred to another pharmacy.',
        statusTag: 'Prescription transfer pending',
        mixedCardStatusTag: 'UNAVAILABLE',
        mixedCardStatusType: PrescriptionStatusType.UNAVAILABLE,
        statusType: PrescriptionStatusType.PENDING,
      }
    case PrescriptionDisplayStatus.ON_HOLD:
      return parseTriagedCopay(copay, copayTriageMessage)
    // ERROR
    case PrescriptionDisplayStatus.INITIALIZED:
      return {
        actionable: 'requestPrice',
        statusMessage: 'Please verify your insurance details and apply any applicable saving cards now. Then click “Request price.”',
        statusTag: 'Request copay price',
        mixedCardStatusTag: 'READY TO ORDER',
        mixedCardStatusType: PrescriptionStatusType.ORDERABLE,
        statusType: PrescriptionStatusType.ERROR,
      }
    case PrescriptionDisplayStatus.OUT_OF_STOCK: {
      return {
        statusTag: 'Medication out of stock',
        statusType: PrescriptionStatusType.ERROR,
        statusMessage: getOutOfStockText(inventory, content),
        mixedCardStatusTag: 'UNAVAILABLE',
        mixedCardStatusType: PrescriptionStatusType.UNAVAILABLE,
      }
    }
    case PrescriptionDisplayStatus.OUT_OF_REFILL:
      return {
        statusMessage:
          'This prescription has zero refills remaining and a new prescription is required. We have reached out to your prescriber, however, in order to expedite the process, you may also contact your prescriber.',
        statusTag: 'No refills remaining',
        statusType: PrescriptionStatusType.ERROR,
        mixedCardStatusTag: 'UNAVAILABLE',
        mixedCardStatusType: PrescriptionStatusType.UNAVAILABLE,
      }
    case PrescriptionDisplayStatus.COPAY_EXPIRED:
      if (copayRejectionMessage) {
        return {
          actionable: 'requestPrice',
          statusMessage: copayRejectionMessage?.copayRequestAbandoned?.message ?? '',
          statusTag: 'Pricing change',
          mixedCardStatusTag: 'READY TO ORDER',
          mixedCardStatusType: PrescriptionStatusType.ORDERABLE,
          statusType: PrescriptionStatusType.ERROR,
          issue: copayRejectionMessage?.copayRequestAbandoned?.displayDescription ? 'Copay Request Abandoned' : '',
          issueDetails: copayRejectionMessage?.copayRequestAbandoned?.details,
          additionalDetails: '',
        }
      }
      return {
        actionable: 'requestPrice',
        statusMessage: 'The previous price is no longer valid. Please request a new price.',
        statusTag: 'Pricing change',
        mixedCardStatusTag: 'READY TO ORDER',
        mixedCardStatusType: PrescriptionStatusType.ORDERABLE,
        statusType: PrescriptionStatusType.ERROR,
      }

    case PrescriptionDisplayStatus.PA_REJECTED:
      return {
        statusMessage: copayRejectionMessage?.priorAuthorizationDenied?.message
          ? copayRejectionMessage.priorAuthorizationDenied.message
          : 'Your insurance has denied the prior authorization request. Please contact your prescriber or your insurance company to discuss alternatives.',
        statusTag: 'Prior authorization denied',
        mixedCardStatusTag: 'READY TO ORDER',
        mixedCardStatusType: PrescriptionStatusType.ORDERABLE,
        statusType: PrescriptionStatusType.ERROR,
      }
    case PrescriptionDisplayStatus.INSURANCE_UNAVAILABLE:
      return {
        statusTag: 'No insurance on file',
        mixedCardStatusTag: 'READY TO ORDER',
        statusMessage:
          'Your prescription has been received, but we do not have your insurance on file. Add insurance to your profile or contact our Customer Support team.',
        statusType: PrescriptionStatusType.ERROR,
        mixedCardStatusType: PrescriptionStatusType.ORDERABLE,
      }
    case PrescriptionDisplayStatus.SCRIPT_EXPIRED:
      return {
        statusMessage:
          'Your prescription has expired and a new prescription is required. We have reached out to your prescriber, however, in order to expedite the process, you may also contact your prescriber.',
        statusTag: 'Prescription expired',
        mixedCardStatusTag: 'UNAVAILABLE',
        mixedCardStatusType: PrescriptionStatusType.UNAVAILABLE,
        statusType: PrescriptionStatusType.ERROR,
      }
    case PrescriptionDisplayStatus.OUT_OF_FORMULARY:
      return {
        statusMessage:
          'Unfortunately, we cannot fill this prescription because it is not part of this program. Please have your prescriber send the prescription to another pharmacy.',
        statusTag: 'Ineligible prescription',
        mixedCardStatusTag: 'UNAVAILABLE',
        mixedCardStatusType: PrescriptionStatusType.UNAVAILABLE,
        statusType: PrescriptionStatusType.ERROR,
      }

    case PrescriptionDisplayStatus.COPAY_ERROR:
      return parseErrorCopay(copay, nextRefillDate, copayRejectionMessage)
    case PrescriptionDisplayStatus.DISCONTINUED_TRANSFERRED:
      return {
        statusTag: 'Prescription no longer available',
        mixedCardStatusTag: 'UNAVAILABLE',
        statusMessage:
          'This prescription has been discontinued or transferred to another pharmacy. Please contact our Customer Support team for more information.',
        statusType: PrescriptionStatusType.ERROR,
        mixedCardStatusType: PrescriptionStatusType.UNAVAILABLE,
      }
    case PrescriptionDisplayStatus.FILL_ERROR:
      return {
        actionable: 'requestPrice',
        statusMessage: 'Please verify your insurance details and apply any applicable saving cards now. Then click “Request price.”',
        statusTag: 'Request copay price',
        mixedCardStatusTag: 'READY TO ORDER',
        mixedCardStatusType: PrescriptionStatusType.ORDERABLE,
        statusType: PrescriptionStatusType.ERROR,
      }
    default:
      return {
        statusTag: 'Unable to fill',
        mixedCardStatusTag: 'UNAVAILABLE',
        statusMessage: 'We are unable to fulfill this prescription. Please contact our Customer Support team to resolve the issue.',
        statusType: PrescriptionStatusType.ERROR,
        mixedCardStatusType: PrescriptionStatusType.UNAVAILABLE,
      }
  }
}

const parseTriagedCopay = (copay: PrescriptionCopay | null, copayTriageMessage?: CopayTriageMessageMicrocopy | null): PrescriptionDisplayInfo => {
  if (copay?.type !== PrescriptionCopayStatusType.TRIAGED || !copayTriageMessage) {
    return {
      statusMessage: `Your price request has been submitted and is being reviewed by our team. No action is required from you. We'll update the status here when completed. Please allow up to 2 business days.`,
      statusTag: 'Copay Price Pending',
      statusType: PrescriptionStatusType.PENDING,
      mixedCardStatusTag: 'NOT READY',
    }
  } else {
    const triagedStatusBase: {
      statusTag: string
      statusType: PrescriptionStatusType
      mixedCardStatusTag?: string
    } = {
      statusTag: 'Copay Price Pending',
      statusType: PrescriptionStatusType.PENDING,
      mixedCardStatusTag: 'NOT READY',
    }
    switch (copay.error_code) {
      case 'CT006':
        return {
          statusMessage: copayTriageMessage?.other.message ?? '',
          ...triagedStatusBase,
          issue: copayTriageMessage?.other.displayDescription ? 'Other' : '',
          issueDetails: copayTriageMessage?.other.details,
          additionalDetails: copayTriageMessage?.other.displayAdditionalInformation ? copay?.description || '' : '',
        }
      case 'CT002':
        return {
          statusMessage: copayTriageMessage?.customerClarificationNeeded.message ?? '',
          ...triagedStatusBase,
          issue: copayTriageMessage?.customerClarificationNeeded.displayDescription ? 'Customer Clarification Needed' : '',
          issueDetails: copayTriageMessage?.customerClarificationNeeded.details,
          additionalDetails: copayTriageMessage.customerClarificationNeeded.displayAdditionalInformation ? copay?.description || '' : '',
        }
      case 'CT004':
        return {
          statusMessage: copayTriageMessage?.refillTooSoon.message ?? '',
          ...triagedStatusBase,
          issue: copayTriageMessage?.refillTooSoon.displayDescription ? 'Refill Too Soon' : '',
          issueDetails: copayTriageMessage?.refillTooSoon.details,
          additionalDetails: copayTriageMessage?.refillTooSoon.displayAdditionalInformation ? copay?.description || '' : '',
        }
      case 'CT007':
        return {
          statusMessage: copayTriageMessage?.coverageTerminated.message ?? '',
          ...triagedStatusBase,
          issue: copayTriageMessage?.coverageTerminated.displayDescription ? 'Coverage Terminated' : '',
          issueDetails: copayTriageMessage?.coverageTerminated.details,
          additionalDetails: copayTriageMessage?.coverageTerminated.displayAdditionalInformation ? copay?.description || '' : '',
        }
      case 'CT008':
        return {
          statusMessage: copayTriageMessage?.coverageNotEffective.message ?? '',
          ...triagedStatusBase,
          issue: copayTriageMessage?.coverageNotEffective.displayDescription ? 'Coverage Not Effective' : '',
          issueDetails: copayTriageMessage?.coverageNotEffective.details,
          additionalDetails: copayTriageMessage?.coverageNotEffective.displayAdditionalInformation ? copay?.description || '' : '',
        }
      case 'CT010':
        return {
          statusMessage: copayTriageMessage?.badInsuranceDetails.message ?? '',
          ...triagedStatusBase,
          issue: copayTriageMessage?.badInsuranceDetails.displayDescription ? 'Bad Insurance Issue' : '',
          issueDetails: copayTriageMessage?.badInsuranceDetails.details,
          additionalDetails: copayTriageMessage?.badInsuranceDetails.displayAdditionalInformation ? copay?.description || '' : '',
        }
      case 'ReviewRequired':
        return {
          statusMessage: copayTriageMessage?.reviewRequired.message ?? '',
          ...triagedStatusBase,
          issue: copayTriageMessage?.reviewRequired.displayDescription ? 'Review Required' : '',
          issueDetails: copayTriageMessage?.reviewRequired.details,
          additionalDetails: copayTriageMessage?.reviewRequired.displayAdditionalInformation ? copay?.description || '' : '',
        }
      case 'CT005':
        return {
          statusMessage: copayTriageMessage?.pendingInternalTransfer.message ?? '',
          ...triagedStatusBase,
          issue: copayTriageMessage?.pendingInternalTransfer.displayDescription ? 'Pending Internal Transfer' : '',
          issueDetails: copayTriageMessage?.pendingInternalTransfer.details,
          additionalDetails: copayTriageMessage?.pendingInternalTransfer.displayAdditionalInformation ? copay?.description || '' : '',
        }
      case 'CT001':
        return {
          statusMessage: copayTriageMessage?.clinicOutreach.message ?? '',
          ...triagedStatusBase,
          issue: copayTriageMessage?.clinicOutreach?.displayDescription ? 'Clinic Outreach' : '',
          issueDetails: copayTriageMessage?.clinicOutreach.details,
          additionalDetails: copayTriageMessage?.clinicOutreach.displayAdditionalInformation ? copay?.description || '' : '',
        }
      case 'CT009':
        return {
          statusMessage: copayTriageMessage?.dateOfBirthMismatch.message ?? '',
          ...triagedStatusBase,
          issue: copayTriageMessage?.dateOfBirthMismatch.displayDescription ? 'Date of Birth Mismatch' : '',
          issueDetails: copayTriageMessage?.dateOfBirthMismatch.details,
          additionalDetails: copayTriageMessage?.dateOfBirthMismatch.displayAdditionalInformation ? copay?.description || '' : '',
        }
      case 'CT012':
        return {
          statusMessage: copayTriageMessage?.planLimitationsExceeded.message ?? '',
          ...triagedStatusBase,
          issue: copayTriageMessage?.planLimitationsExceeded.displayDescription ? 'Plan Limitation Exceeded' : '',
          issueDetails: copayTriageMessage?.planLimitationsExceeded.details,
          additionalDetails: copayTriageMessage?.planLimitationsExceeded.displayAdditionalInformation ? copay?.description || '' : '',
        }
      case 'T060':
        return {
          statusMessage: copayTriageMessage?.weatherConditionDelay.message ?? '',
          ...triagedStatusBase,
          issue: copayTriageMessage?.planLimitationsExceeded.displayDescription ? 'Plan Limitation Exceeded' : '',
          issueDetails: copayTriageMessage?.planLimitationsExceeded.details,
          additionalDetails: copayTriageMessage?.planLimitationsExceeded.displayAdditionalInformation ? copay?.description || '' : '',
        }
      default:
        return {
          statusMessage: `Your price request has been submitted and is being reviewed by our team. No action is required from you. We'll update the status here when completed. Please allow up to 2 business days.`,
          ...triagedStatusBase,
          additionalDetails: copay?.description || '',
        }
    }
  }
}

const parseErrorCopay = (
  copay: PrescriptionCopay | null,
  nextRefillDate: string | null,
  copayRejectionMessage?: CopayRejectionMessageMicrocopy | null,
): PrescriptionDisplayInfo => {
  if (copay?.type !== PrescriptionCopayStatusType.REJECTED || !copayRejectionMessage) {
    return {
      statusHtml: errorStatusHtml,
      statusTag: 'Insurance issue',
      mixedCardStatusTag: 'READY TO ORDER',
      mixedCardStatusType: PrescriptionStatusType.ORDERABLE,
      statusType: PrescriptionStatusType.ERROR,
    }
  } else {
    const rejectedStatusBase: {
      statusTag: string
      statusType: PrescriptionStatusType
      mixedCardStatusTag?: string
      mixedCardStatusType?: PrescriptionStatusType
    } = {
      statusTag: 'Insurance issue',
      statusType: PrescriptionStatusType.ERROR,
      mixedCardStatusTag: 'READY TO ORDER',
      mixedCardStatusType: PrescriptionStatusType.ORDERABLE,
    }
    switch (copay.error_code) {
      case CopayErrorCode.MAIL_ORDER_FILLS_EXCEEDED:
        return {
          actionable: 'requestPrice',
          statusMessage: copayRejectionMessage?.mailOrderFillsExceeded?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.mailOrderFillsExceeded?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.mailOrderFillsExceeded.details,
          additionalDetails: copayRejectionMessage?.mailOrderFillsExceeded?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.AUTO_REVERSAL_REQUIRED:
        return {
          actionable: 'requestPrice',
          statusMessage: copayRejectionMessage?.autoReversalRequired?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.autoReversalRequired?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.autoReversalRequired.details,
          additionalDetails: copayRejectionMessage?.autoReversalRequired?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.COPAY_REQUEST_ABANDONED:
        return {
          actionable: 'requestPrice',
          statusMessage: copayRejectionMessage?.copayRequestAbandoned?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.copayRequestAbandoned?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.copayRequestAbandoned.details,
          additionalDetails: copayRejectionMessage?.copayRequestAbandoned?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.COVERAGE_TERMINATED:
        return {
          statusMessage: copayRejectionMessage?.coverageTerminated?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.coverageTerminated?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.coverageTerminated.details,
          additionalDetails: copayRejectionMessage?.coverageTerminated?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.NOT_COVERED_BY_INSURANCE:
        return {
          actionable: 'requestPrice',
          statusMessage: copayRejectionMessage?.notCoveredByInsurance?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.notCoveredByInsurance?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.notCoveredByInsurance.details,
          additionalDetails: copayRejectionMessage?.notCoveredByInsurance?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.OUT_OF_NETWORK:
        return {
          statusMessage: copayRejectionMessage?.outOfNetwork?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.outOfNetwork?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.outOfNetwork.details,
          additionalDetails: copayRejectionMessage?.outOfNetwork?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.PHARMACY_NOT_ENROLLED_IN_MEDICAID_PROGRAM:
        return {
          statusMessage: copayRejectionMessage?.pharmacyOutOfNetworkStateMedicaid?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.pharmacyOutOfNetworkStateMedicaid?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.pharmacyOutOfNetworkStateMedicaid.details,
          additionalDetails: copayRejectionMessage?.pharmacyOutOfNetworkStateMedicaid?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.PRESCRIPTION_NO_MORE_REFILLS:
        return {
          statusMessage: copayRejectionMessage?.rxNoRefills?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.rxNoRefills?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.rxNoRefills.details,
          additionalDetails: copayRejectionMessage?.rxNoRefills?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.PRESCRIPTION_TRANSFERRED:
        return {
          statusMessage: copayRejectionMessage?.prescriptionTransferred?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.prescriptionTransferred?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.prescriptionTransferred.details,
          additionalDetails: copayRejectionMessage?.prescriptionTransferred?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.PRIMARY_INSURANCE_REQUIRED:
        return {
          statusMessage: copayRejectionMessage?.primaryInsuranceRequired?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.primaryInsuranceRequired?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.primaryInsuranceRequired.details,
          additionalDetails: copayRejectionMessage?.primaryInsuranceRequired?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.QUANTITY_LIMITED_BY_INSURANCE:
        return {
          actionable: 'requestPrice',
          statusMessage: copayRejectionMessage?.quantityLimitedByInsurance?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.quantityLimitedByInsurance?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.quantityLimitedByInsurance.details,
          additionalDetails: copayRejectionMessage?.quantityLimitedByInsurance?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.REFILL_TOO_SOON:
        return {
          statusMessage: `This prescription was recently filled. You can re-order on ${getFormattedDate(nextRefillDate)}.`,
          statusTag: 'Refill too soon',
          statusType: PrescriptionStatusType.PENDING,
        }
      case CopayErrorCode.OTHER:
        return {
          actionable: 'requestPrice',
          statusMessage: copayRejectionMessage?.other?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.other?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.other.details,
          additionalDetails: copayRejectionMessage?.other?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.HIGH_COPAY:
        return {
          actionable: 'requestPrice',
          statusMessage: copayRejectionMessage?.highCopay?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.highCopay?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.highCopay.details,
          additionalDetails: copayRejectionMessage?.highCopay?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.INSURANCE_ISSUE:
        return {
          statusMessage: copayRejectionMessage?.insuranceIssue?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.insuranceIssue?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.insuranceIssue.details,
          additionalDetails: copayRejectionMessage?.insuranceIssue?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.MISSING_INSURANCE_INFORMATION:
        return {
          statusMessage: copayRejectionMessage?.invalidInsuranceInformation?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.invalidInsuranceInformation?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.invalidInsuranceInformation.details,
          additionalDetails: copayRejectionMessage?.invalidInsuranceInformation?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.PRESCRIPTION_ISSUE:
        return {
          statusMessage: copayRejectionMessage?.prescriptionIssue?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.prescriptionIssue?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.prescriptionIssue.details,
          additionalDetails: copayRejectionMessage?.prescriptionIssue?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.PRIOR_AUTHORIZATION_DENIED:
        return {
          statusMessage: copayRejectionMessage?.priorAuthorizationDenied?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.priorAuthorizationDenied?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.priorAuthorizationDenied.details,
          additionalDetails: copayRejectionMessage?.priorAuthorizationDenied?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.PRIOR_AUTHORIZATION_REQUIRED:
        return {
          statusMessage: copayRejectionMessage?.priorAuthorizationNeeded?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.priorAuthorizationNeeded?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.priorAuthorizationNeeded.details,
          additionalDetails: copayRejectionMessage?.priorAuthorizationNeeded?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      case CopayErrorCode.DUPLICATE_REQUEST:
        return {
          statusMessage: copayRejectionMessage?.duplicateCopayRequest?.message ?? '',
          ...rejectedStatusBase,
          issue: copayRejectionMessage?.duplicateCopayRequest?.displayDescription ? copay?.error_code || '' : '',
          issueDetails: copayRejectionMessage?.duplicateCopayRequest.details,
          additionalDetails: copayRejectionMessage?.duplicateCopayRequest?.displayAdditionalInformation ? copay?.error_message || '' : '',
        }
      default:
        return {
          statusHtml: errorStatusHtml,
          statusTag: 'Insurance issue',
          statusType: PrescriptionStatusType.ERROR,
          mixedCardStatusTag: 'READY TO ORDER',
          mixedCardStatusType: PrescriptionStatusType.ORDERABLE,
        }
    }
  }
}

export const getPrescriptionDisplayName = (prescription: MedicationInfo): string =>
  prescription.genericDisplayName ? `${prescription.brandDisplayName} (${prescription.genericDisplayName})` : prescription.brandDisplayName

export const getOutOfStockText = (inventory: MedicationInventory | null, content?: PrescriptionCardMicrocopy): string => {
  if (inventory?.availableDate && content?.outOfStockWithDate) {
    const normalizedFormat = 'YYYY-MM-DD'
    const normalizedAvailableDate = utc(inventory.availableDate).format(normalizedFormat)
    // Preserve user's timezone
    const normalizedToday = moment(new Date()).format(normalizedFormat)
    if (!moment(normalizedToday).isSameOrAfter(normalizedAvailableDate)) {
      return replaceHandlebars({ availableDate: utc(inventory.availableDate).format('MMMM D, YYYY') }, content.outOfStockWithDate)
    }
  }

  return content?.outOfStock ? content.outOfStock : 'This medication is currently out of stock. Please check back soon.'
}
