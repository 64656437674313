import { yupResolver } from '@hookform/resolvers/yup'
import { BannerAlert, Header, Spacer, Text, Theme } from '@truepill/react-capsule'
import { VpTheme } from '@vpharm-platform/shared'
import { PatientInsurance, PatientInsuranceSubmitPayload } from 'interfaces'
import React, { ReactElement, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import { ThemedSelect } from '../../../common/styledComponents/ThemedSelect'
import { ThemedTextField } from '../../../common/styledComponents/ThemedTextField'
import { InsurancePageMicrocopy } from '../../../hooks/contentful/types/microcopy'
import PageAlert from '../../../pages/AccountManagement/PageAlert'
import { parseTextFieldStateForCapsule } from '../../../utils'
import { InsuranceFieldValidationSchema } from '../../../utils/validation'
import { useLDContextWithLocalStorage } from '../../LDProvider'
import LoadingAnimation from '../../LoadingAnimation'
import WarningBanner from '../../WarningBanner/warningBanner'
import { InsuranceImageUpload } from './InsuranceImageUpload'
import { SampleInsuranceCard } from './SampleInsuranceCard'
import { FormGrid, LoadingAnimationWrapper, StyledActionContainer } from './styledComponents'
import { InsuranceFormFields } from './types'
import { useInsuranceImageUpload } from './useInsuranceImageUpload'

export interface InsuranceFormProps {
  pageContent?: InsurancePageMicrocopy
  theme: VpTheme
  currentInsurance: PatientInsurance | null
  formErrorMessage?: string | null
  handleSubmitForm: (val: PatientInsuranceSubmitPayload) => Promise<void>
  handleCancelForm: () => void
}

const InsuranceForm = ({
  pageContent,
  theme,
  currentInsurance,
  formErrorMessage,
  handleSubmitForm,
  handleCancelForm,
}: InsuranceFormProps): ReactElement => {
  const form = useForm<InsuranceFormFields>({
    resolver: yupResolver(InsuranceFieldValidationSchema),
    mode: 'onBlur',
    delayError: 1000,
  })

  const {
    control,
    formState: { errors, dirtyFields, isValid, isSubmitting },
    handleSubmit,
    register,
    setValue,
    clearErrors,
    watch,
    reset,
  } = form

  const [isInsuranceImageLoading, setIsInsuranceImageLoading] = useState(false)
  const { isUploadingInsuranceImage, uploadInsuranceImage, customerProfile } = useInsuranceImageUpload()
  const featureFlags = useLDContextWithLocalStorage()
  const isAutoRefillEnabled = featureFlags.autoRefills && customerProfile.autoRefills

  useEffect(() => {
    if (currentInsurance) {
      reset(currentInsurance)
    }
  }, [currentInsurance, reset])

  const [cardHolderID, rxBin, pcn, rxGroup] = watch(['cardholder_id', 'rx_bin', 'pcn', 'rx_group'])

  const relationshipOptions: ('Cardholder' | 'Spouse' | 'Child' | 'Other')[] = ['Cardholder', 'Spouse', 'Child', 'Other']

  const optionalLabelText = '(if included on card)'

  const fieldConfig = {
    cardholderId: {
      label: 'Cardholder Id',
      placeholder: 'B03100136180',
      required: true,
    },
    rxBIN: {
      label: 'Rx BIN',
      placeholder: '601341',
      required: true,
    },
    rxPCN: {
      label: 'Rx PCN',
      placeholder: 'OHCP',
      required: false,
    },
    rxGroup: {
      label: 'Rx Group',
      placeholder: 'OH9007041',
      required: false,
    },
    relationshipToCardholder: {
      label: 'Relationship to Cardholder',
      placeholder: 'Select',
      required: true,
    },
  }

  const handleCancelFormWrapper = (e: React.MouseEvent) => {
    e.preventDefault()
    handleCancelForm()
  }

  const handleSubmitFormWrapper = async (values: InsuranceFormFields) => {
    const insuranceSubmitPayload: PatientInsuranceSubmitPayload = {
      cardholder_id: values.cardholder_id,
      rx_bin: values.rx_bin,
      pcn: values.pcn,
      rx_group: values.rx_group,
      insurance_image_name: values.insurance_image_name,
      relationship_to_primary_cardholder: values.relationship_to_primary_cardholder,
    }
    await handleSubmitForm(insuranceSubmitPayload)
  }

  return (
    <>
      {isSubmitting && (
        <LoadingAnimationWrapper vpTheme={theme}>
          <LoadingAnimation />
        </LoadingAnimationWrapper>
      )}
      <Header id='insurance-modal-title' variant='4xl'>
        {currentInsurance ? 'Edit pharmacy insurance' : 'Add pharmacy insurance'}
      </Header>
      <Spacer size='lg' />
      {pageContent?.insuranceModalDisclaimer && <WarningBanner richText={pageContent?.insuranceModalDisclaimer} />}
      {isAutoRefillEnabled ? (
        <PageAlert
          text={
            'Modifying an existing insurance OR adding a new insurance may change the prices for any medications which are currently available to order, and will disable any existing prescription auto refills.'
          }
        />
      ) : (
        <PageAlert
          text={
            'Modifying an existing insurance OR adding a new insurance may change the prices for any medications which are currently available to order.'
          }
        />
      )}

      <Spacer size='sm' />
      <Text css={{ color: theme.colors['typography-medium'] }}>
        {currentInsurance ? 'Any new edits will replace existing insurance information.' : ''}
      </Text>
      <Spacer size='md' />
      {formErrorMessage && (
        <>
          <BannerAlert state='error'>
            <Text>{formErrorMessage}</Text>
          </BannerAlert>
          <Spacer size='lg' />
        </>
      )}
      <Theme theme={theme}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(handleSubmitFormWrapper)}>
            <FormGrid>
              <div data-testid='relationship-to-cardholder-container'>
                <Controller
                  name='relationship_to_primary_cardholder'
                  control={control}
                  render={({ field }) => (
                    <ThemedSelect
                      required
                      label={`${fieldConfig.relationshipToCardholder.label} ${
                        !fieldConfig.relationshipToCardholder.required ? optionalLabelText : ''
                      }`}
                      value={relationshipOptions.find((option) => option === field.value) || ''}
                      options={relationshipOptions}
                      onChange={(value: string | undefined) => {
                        if (value) {
                          setValue('relationship_to_primary_cardholder', value, {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true,
                          })
                        }
                      }}
                      placeholder={fieldConfig.relationshipToCardholder.placeholder}
                      state={parseTextFieldStateForCapsule(errors.relationship_to_primary_cardholder, dirtyFields.relationship_to_primary_cardholder)}
                      className={`${fieldConfig.relationshipToCardholder.label} ${
                        !fieldConfig.relationshipToCardholder.required ? optionalLabelText : ''
                      }`}
                      vpTheme={theme}
                    />
                  )}
                />
              </div>
              <Spacer size='xl' />
              <ThemedTextField
                label={`${fieldConfig.cardholderId.label} ${!fieldConfig.cardholderId.required ? optionalLabelText : ''}`}
                {...register('cardholder_id', {
                  onChange: () => {
                    clearErrors('cardholder_id')
                  },
                })}
                placeholder={fieldConfig.cardholderId.placeholder}
                helperText={errors.cardholder_id?.message}
                state={parseTextFieldStateForCapsule(errors.cardholder_id, cardHolderID !== '')}
                aria-label={`${fieldConfig.cardholderId.label} ${!fieldConfig.cardholderId.required ? optionalLabelText : ''}`}
                required={fieldConfig.cardholderId.required}
                vpTheme={theme}
              />
              <ThemedTextField
                label={`${fieldConfig.rxBIN.label} ${!fieldConfig.rxBIN.required ? optionalLabelText : ''}`}
                {...register('rx_bin', {
                  onChange: () => {
                    clearErrors('rx_bin')
                  },
                })}
                css={{
                  '.test::after': {
                    content: 'Type your text here',
                    display: 'block',
                  },
                }}
                className='test'
                placeholder={fieldConfig.rxBIN.placeholder}
                helperText={errors.rx_bin?.message}
                state={parseTextFieldStateForCapsule(errors.rx_bin, rxBin !== '')}
                aria-label={`${fieldConfig.rxBIN.label} ${!fieldConfig.rxBIN.required ? optionalLabelText : ''}`}
                required={fieldConfig.rxBIN.required}
                maxLength={6}
                vpTheme={theme}
              />
              <ThemedTextField
                label={`${fieldConfig.rxPCN.label} ${!fieldConfig.rxPCN.required ? optionalLabelText : ''}`}
                {...register('pcn', {
                  onChange: () => {
                    clearErrors('pcn')
                  },
                })}
                placeholder={fieldConfig.rxPCN.placeholder}
                helperText={errors.pcn?.message}
                state={parseTextFieldStateForCapsule(errors.pcn, pcn !== '')}
                aria-label={`${fieldConfig.rxPCN.label} ${!fieldConfig.rxPCN.required ? optionalLabelText : ''}`}
                required={fieldConfig.rxPCN.required}
                vpTheme={theme}
              />
              <ThemedTextField
                label={`${fieldConfig.rxGroup.label} ${!fieldConfig.rxGroup.required ? optionalLabelText : ''}`}
                {...register('rx_group', {
                  onChange: () => {
                    clearErrors('rx_group')
                  },
                })}
                placeholder={fieldConfig.rxGroup.placeholder}
                helperText={errors.rx_group?.message}
                state={parseTextFieldStateForCapsule(errors.rx_group, rxGroup !== '')}
                aria-label={`${fieldConfig.rxGroup.label} ${!fieldConfig.rxGroup.required ? optionalLabelText : ''}`}
                required={fieldConfig.rxGroup.required}
                vpTheme={theme}
              />
            </FormGrid>
            <Spacer size='xl' />
            <SampleInsuranceCard />
            <Spacer size='xl' />
            <InsuranceImageUpload
              insuranceCardImageUrl={currentInsurance?.insurance_card_image_url}
              isUploadingInsuranceImage={isUploadingInsuranceImage}
              uploadInsuranceImage={uploadInsuranceImage}
              setIsInsuranceImageLoading={setIsInsuranceImageLoading}
            />
            <StyledActionContainer>
              <ThemedButton
                disabled={isUploadingInsuranceImage || isSubmitting}
                variant='primary-text'
                onClick={(e) => handleCancelFormWrapper(e)}
                vpTheme={theme}
                role='button'
              >
                {'Cancel'}
              </ThemedButton>
              <ThemedButton role='button' type='submit' disabled={!isValid || isSubmitting || isInsuranceImageLoading} vpTheme={theme}>
                {'Save'}
              </ThemedButton>
            </StyledActionContainer>
          </form>
        </FormProvider>
      </Theme>
    </>
  )
}

export default InsuranceForm
