import { yupResolver } from '@hookform/resolvers/yup'
import { Header, Spacer } from '@truepill/react-capsule'
import { formatToPhone, FormularyMedication, GetTransferPharmaciesResponse, PrescriptionDetailsFormValues, VpTheme } from '@vpharm-platform/shared'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { TransferDetailsValidationSchema } from 'utils/validation'

import { StepOneIcon, StepTwoIcon } from '../../../assets/Icons'
import { ThemedTextField } from '../../../common/styledComponents/ThemedTextField'
import { UpDownChevron } from '../../../Components/AddressForms/upDownChevron'
import { useCustomerConfigContext } from '../../../Components/CustomerConfigProvider'
import GooglePlacesAutocomplete, { Place } from '../../../Components/GooglePlacesAutocomplete'
import LoadingAnimation from '../../../Components/LoadingAnimation'
import { Subtitle } from '../../../Components/Subtitle'
import WarningBanner from '../../../Components/WarningBanner/warningBanner'
import { TRANSFER_IN_PAGE_ENHANCEMENTS } from '../../../constants/feature-flags'
import { useCustomerProfile } from '../../../hooks'
import { useAnalytics } from '../../../hooks/analytics-context'
import { TransferInPageMicrocopy } from '../../../hooks/contentful/types/microcopy'
import { useLDFlagsWithLocalStorage } from '../../../hooks/useLDFlagsWithLocalStorage'
import { useTransferInPharmacy } from '../../../hooks/useTransferInPharmacy'
import { FormularyService } from '../../../services/FormularyService'
import { parseTextFieldStateForCapsule } from '../../../utils'
import { FormularyMedicationWithFormattedName } from './AutocompleteDropdown'
import PrescriptionInformation from './PrescriptionInformation'
import {
  ContainerGroup,
  ErrorMessage,
  Form,
  HrSpacer,
  StepDescriptionText,
  StepTitleContainer,
  StepTitleText,
  SubmitButton,
  SubmitButtonContainer,
  TextFieldContainer,
  ToggleManualPharmacyEntry,
} from './styledComponents'
import TransferInClosedPharmacy from './TransferInClosedPharmacy'
import { TransferSimulation, UseTransferSimulation } from './TransferSimulation'

interface Props {
  theme: VpTheme
  content?: TransferInPageMicrocopy
  prescriptions: PrescriptionDetailsFormValues[]
  transferSimulation: UseTransferSimulation
  onSubmit: (formData: TransferFormFields) => void
  onAddPrescription: (formData: PrescriptionDetailsFormValues) => void
  onDeletePrescription: (medicationName: string) => void
}

export interface MedicationDetails {
  medication: FormularyMedicationWithFormattedName
  medicationStrength?: string | null
  medicationQuantity?: number | null
}

export interface TransferFormFields {
  medicationDetails: MedicationDetails
  pharmacyDetails: {
    pharmacyName: string
    pharmacyPhoneNumber: string
    pharmacyFaxNumber: string
  }
}

export type AutocompleteState = 'default' | 'complete' | 'error'

const TransferDetailsForm: React.FC<Props> = ({
  theme,
  content,
  prescriptions,
  transferSimulation,
  onSubmit,
  onAddPrescription,
  onDeletePrescription,
}) => {
  const formController = useForm<TransferFormFields>({
    resolver: yupResolver(TransferDetailsValidationSchema),
    mode: 'onChange',
  })
  const {
    register,
    getValues,
    setValue,
    formState: { errors, dirtyFields },
  } = formController
  const shouldShowTransferSimulation = useMemo(() => process.env.REACT_APP_VPP_ENV && process.env.REACT_APP_VPP_ENV !== 'production', [])
  const { customerProfile } = useCustomerProfile()
  const [, setFormularyMedications] = useState<FormularyMedicationWithFormattedName[]>([])
  const [addManualPharmacy, setAddManualPharmacy] = useState(false)
  const { pharmCustomer } = useCustomerConfigContext()
  const [selectedPharmacy, setSelectedPharmacy] = useState<GetTransferPharmaciesResponse | null>(null)
  const { transferInPharmacies, loading } = useTransferInPharmacy(customerProfile.vpharmCustomerToken)
  const { [TRANSFER_IN_PAGE_ENHANCEMENTS]: enhancementsFeatureFlag } = useLDFlagsWithLocalStorage([TRANSFER_IN_PAGE_ENHANCEMENTS])

  const { trackButtonClickEvent, trackTextFieldChangeEvent } = useAnalytics()

  useEffect(() => {
    const fetchFormulary = (): Promise<FormularyMedication[]> => {
      const formularyService = new FormularyService()
      return formularyService.getFormulary(customerProfile.vpharmCustomerToken)
    }

    fetchFormulary().then((formularyMedications) => {
      const medications = formularyMedications.map((item) => {
        const formularyMedicationWithFormattedName: FormularyMedicationWithFormattedName = {
          ...item,
          formattedName: item.drugName + (item.genericName ? ` (${item.genericName})` : ''),
          genericName: item.genericName || '',
          drugName: item.drugName || '',
        }
        return formularyMedicationWithFormattedName
      })
      setFormularyMedications(medications)
    })
  }, [customerProfile.vpharmCustomerToken])

  const isSubmittable = () => {
    const formValues = getValues()
    return (
      (!errors.pharmacyDetails &&
        formValues.pharmacyDetails?.pharmacyName &&
        formValues.pharmacyDetails?.pharmacyPhoneNumber &&
        !errors.medicationDetails &&
        formValues.medicationDetails?.medication?.ndc) ||
      prescriptions.length
    )
  }

  const handlePlaceChange = (place: Place) => {
    setValue('pharmacyDetails.pharmacyName', place.name, { shouldValidate: true })
    setValue('pharmacyDetails.pharmacyPhoneNumber', place.phoneNumber, { shouldValidate: true })
    trackTextFieldChangeEvent('pharmacyDetails autocomplete set', '/transfers/request')
  }

  const handleOnSelectedClosedPharmacy = (pharmacy: GetTransferPharmaciesResponse) => {
    setSelectedPharmacy(pharmacy)

    const pharmacyName: string = pharmacy.pharmacy_name
    const pharmacyPhoneNumber: string = pharmacy.pharmacy_phone
    const pharmacyFaxNumber: string = pharmacy.pharmacy_fax
    setValue('pharmacyDetails.pharmacyName', pharmacyName, { shouldValidate: true })
    setValue('pharmacyDetails.pharmacyFaxNumber', pharmacyFaxNumber, { shouldValidate: false })
    setValue('pharmacyDetails.pharmacyPhoneNumber', pharmacyPhoneNumber, { shouldValidate: true })
    trackTextFieldChangeEvent('pharmacyDetails autocomplete set', '/transfers/request')
  }

  const onAddManualPharmacyClick = () => {
    trackButtonClickEvent('add-manual-pharmacy', 'Don’t see your pharmacy?', 'Open manual pharmacy entry')
    setAddManualPharmacy(!addManualPharmacy)
  }

  return loading ? (
    <LoadingAnimation />
  ) : (
    <>
      {content?.transferPageInsuranceDisclaimer && <WarningBanner richText={content?.transferPageInsuranceDisclaimer} />}
      <Spacer size='lg' />
      <Header bold variant='3xl'>
        Transfer Prescriptions to {pharmCustomer?.displayName}
      </Header>
      <Spacer size='xs' />
      <Subtitle>Transfer your current prescriptions from an existing pharmacy in two easy steps. </Subtitle>
      <Form>
        <StepTitleContainer>
          <StepOneIcon vpTheme={theme} />
          <StepTitleText>Identify current pharmacy</StepTitleText>
        </StepTitleContainer>
        {customerProfile.transfersClosedPharmacy && enhancementsFeatureFlag && transferInPharmacies && transferInPharmacies?.length > 0 ? (
          <>
            <StepDescriptionText>Select the pharmacy that currently manages your prescription.</StepDescriptionText>
            <TransferInClosedPharmacy
              pharmacies={transferInPharmacies}
              selectedPharmacy={selectedPharmacy?.pharmacy_name}
              onSelectPharmacy={handleOnSelectedClosedPharmacy}
            ></TransferInClosedPharmacy>
          </>
        ) : (
          <>
            <StepDescriptionText>Search and select the pharmacy you currently use to get your prescription(s) filled. </StepDescriptionText>
            <TextFieldContainer>
              <GooglePlacesAutocomplete onChange={handlePlaceChange} initValue={''} disabled={addManualPharmacy} />
            </TextFieldContainer>
            <ToggleManualPharmacyEntry type='button' variant='primary-text' onClick={onAddManualPharmacyClick} vpTheme={theme}>
              Don’t see your pharmacy?
              <Spacer size='xs' />
              <UpDownChevron rotated={!addManualPharmacy} flipped={!addManualPharmacy} />
            </ToggleManualPharmacyEntry>
          </>
        )}
        {addManualPharmacy && (
          <>
            <Spacer />
            <Subtitle>Manually enter your current pharmacy details.</Subtitle>
            <Spacer />

            <ContainerGroup>
              <TextFieldContainer>
                <ThemedTextField
                  label='Pharmacy name *'
                  placeholder='e.g. CVS, Walgreens'
                  {...register('pharmacyDetails.pharmacyName')}
                  required
                  state={parseTextFieldStateForCapsule(errors.pharmacyDetails?.pharmacyName, dirtyFields.pharmacyDetails?.pharmacyName)}
                  vpTheme={theme}
                />
                {errors.pharmacyDetails?.pharmacyName?.message && (
                  <ErrorMessage vpTheme={theme}>{errors.pharmacyDetails?.pharmacyName.message}</ErrorMessage>
                )}
              </TextFieldContainer>
              <TextFieldContainer>
                <ThemedTextField
                  label='Pharmacy phone number *'
                  placeholder='888-555-1234'
                  {...register('pharmacyDetails.pharmacyPhoneNumber', {
                    onChange: (e: ChangeEvent<HTMLInputElement>) => setValue('pharmacyDetails.pharmacyPhoneNumber', formatToPhone(e.target.value)),
                  })}
                  required
                  state={parseTextFieldStateForCapsule(errors.pharmacyDetails?.pharmacyPhoneNumber, dirtyFields.pharmacyDetails?.pharmacyPhoneNumber)}
                  vpTheme={theme}
                />
                {errors.pharmacyDetails?.pharmacyPhoneNumber?.message && (
                  <ErrorMessage vpTheme={theme}>{errors.pharmacyDetails?.pharmacyPhoneNumber.message}</ErrorMessage>
                )}
              </TextFieldContainer>
            </ContainerGroup>
          </>
        )}
        <HrSpacer vpTheme={theme} />
        <StepTitleContainer>
          <StepTwoIcon vpTheme={theme} />
          <StepTitleText>Enter Your Prescription</StepTitleText>
        </StepTitleContainer>
        <StepDescriptionText>Enter details for the medication you want us to transfer from your current pharmacy.</StepDescriptionText>
        <PrescriptionInformation
          formController={formController}
          onAddPrescription={onAddPrescription}
          onRemovePrescription={onDeletePrescription}
          prescriptions={prescriptions}
        />
        <SubmitButtonContainer>
          <SubmitButton
            type='button'
            disabled={!isSubmittable()}
            onClick={() => {
              trackButtonClickEvent('submit-transfer-request', 'Submit transfer request')
              onSubmit(getValues())
            }}
            vpTheme={theme}
          >
            Submit transfer request
          </SubmitButton>
        </SubmitButtonContainer>
        {shouldShowTransferSimulation && <TransferSimulation {...transferSimulation} />}
      </Form>
      <Spacer size='xl' />
    </>
  )
}

export default TransferDetailsForm
