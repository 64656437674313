import { Elements } from '@stripe/react-stripe-js'
import { SEO, Spacer, Text } from '@truepill/react-capsule'
import { MainScreen, SecondaryScreen, SplitView, SplitViewHeader } from 'common/styledComponents/SplitViewStyle'
import OrderSummary from 'Components/Body/OrderSummary'
import SpinnerScreen from 'Components/SpinnerScreen'
import React, { StrictMode, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { initializeStripe } from 'services/StripeService'

import CheckoutProvider, { useCheckoutContext } from '../../Components/Checkout/CheckoutProvider'
import CheckoutOrderReview from '../../Components/Checkout/OrderReview'
import CheckoutPayment from '../../Components/Checkout/Payment'
import CheckoutShipping from '../../Components/Checkout/Shipping'
import SupportLink from '../../Components/SupportLink'
import { ORDER_CONFIRMATION_PATH } from '../../constants'
import { useGetCustomerNameFromSubdomain, useRefreshPrescriptionList } from '../../hooks'
import { CheckoutPageMicrocopy } from '../../hooks/contentful/types/microcopy'
import { useGetPageContent } from '../../hooks/contentful/useGetPageContent'
import { AccordionWrapper, CheckoutContainer, ErrorMessage } from './styledComponents'

const DEFAULT_ERROR_MESSAGE = 'Something went wrong please try again'

const Checkout: React.FC = () => {
  const history = useHistory()
  const stripePromise = initializeStripe()
  const { refreshPrescriptionList } = useRefreshPrescriptionList()
  const { priceDetails, medicationsInCart } = useCheckoutContext()
  const customerName = useGetCustomerNameFromSubdomain()
  const { seo } = useGetPageContent<CheckoutPageMicrocopy>('checkoutPage')

  const [isSubmittingOrder, setIsSubmittingOrder] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')

  if (medicationsInCart.length === 0) {
    history.replace('/cart')
  }

  const handleShowErrorMessage = (message?: string) => {
    setErrorMsg(message || DEFAULT_ERROR_MESSAGE)
    setTimeout(() => setErrorMsg(''), 5000)
  }

  const goToOrderConfirmation = useCallback(
    (orderToken: string) => {
      try {
        // Warning: these recoil reseting cause a page refreshing
        // and could lead to undesired behavior if you want to change
        // the state of this component right after (e.g: display a modal)
        refreshPrescriptionList()
      } catch (e) {}
      history.replace(ORDER_CONFIRMATION_PATH.replace(':order_id', orderToken), { prevPath: '/checkout' })
    },
    [history, refreshPrescriptionList],
  )

  const onSubmitOrder = useCallback(
    (orderToken: string) => {
      goToOrderConfirmation(orderToken)
    },
    [goToOrderConfirmation],
  )

  return (
    <StrictMode>
      {seo?.fields.title && <SEO title={seo.fields.title || customerName} description={seo.fields.description} useDefaults />}
      <CheckoutContainer>
        {isSubmittingOrder && (
          <SpinnerScreen
            processingText='Processing your order...'
            description='This won’t take long, you’ll receive a confirmation shortly.'
            subDescription='Please do not refresh or close this window.'
          />
        )}
        <>
          <SplitView>
            <SplitViewHeader bold variant='4xl'>
              Checkout
            </SplitViewHeader>
            <MainScreen>
              <AccordionWrapper>
                <CheckoutShipping />
                <Spacer />
                <Elements options={{ fonts: [{ family: 'Lato', cssSrc: 'https://use.typekit.net/ysv7wrb.css' }] }} stripe={stripePromise}>
                  <CheckoutPayment />
                  <Spacer />
                  <CheckoutOrderReview
                    onSubmitOrder={onSubmitOrder}
                    onShowErrorMessage={handleShowErrorMessage}
                    setIsSubmittingOrder={setIsSubmittingOrder}
                  />
                </Elements>
              </AccordionWrapper>
            </MainScreen>
            <SecondaryScreen>
              <OrderSummary priceDetails={priceDetails} checkoutFlow={true} />
            </SecondaryScreen>
          </SplitView>
          <ErrorMessage state='error' visible={!!errorMsg}>
            <Text>
              {errorMsg.includes('contact Patient Support') ? (
                <>
                  {errorMsg.replace('contact Patient Support', '')} <SupportLink>contact Patient Support</SupportLink>.
                </>
              ) : (
                errorMsg
              )}
            </Text>
          </ErrorMessage>
        </>
      </CheckoutContainer>
    </StrictMode>
  )
}

const CheckoutPage: React.FC = () => {
  return (
    <CheckoutProvider>
      <Checkout />
    </CheckoutProvider>
  )
}

export default CheckoutPage
