import { CustomerProfileResponse, PatientProfileResponse, UserProfileResponse } from '@vpharm-platform/shared'

import { CustomerProfile, Patient, PatientProfile, UserProfile } from '../../interfaces'

export const transformProfileResponse = {
  customerProfile(res: CustomerProfileResponse): CustomerProfile {
    return {
      vpharmCustomerToken: res.vpharm_customer_token,
      auth0Audience: res.auth0_audience,
      auth0ClientId: res.auth0_client_id,
      auth0Domain: res.auth0_domain,
      customerName: res.customer_name as string,
      allowPriceTransparency: res.allow_price_transparency,
      allowSelfEnrollment: res.allow_self_enrollment,
      allowPrescriptionTransfers: res.allow_prescription_transfers,
      isBeingTerminated: res.is_being_terminated,
      cashOnly: res.cash_only,
      mixedCart: res.mixed_cart,
      allowLiveChat: res.allow_live_chat,
      transferOut: res.transfer_out,
      autoRefills: res.auto_refills,
      transfersClosedPharmacy: res.transfers_closed_pharmacy,
      pharmacyInfo: {
        name: res.pharmacy_info.name,
        address1: res.pharmacy_info.address1,
        address2: res.pharmacy_info.address2,
        zip: res.pharmacy_info.zip,
        state: res.pharmacy_info.state,
        city: res.pharmacy_info.city,
        faxNumber: res.pharmacy_info.fax_number,
        phoneNumber: res.pharmacy_info.phone_number,
        ncpdp: res.pharmacy_info.ncpdp,
      },
    }
  },
  patientProfile(res: PatientProfileResponse): PatientProfile {
    return {
      idUploaded: res.id_uploaded,
      showPregnancyQuestion: res.show_pregnancy_question,
      communicationsOptIn: res.communications_opt_in,
      email: res.email,
      phone: res.phone,
      patientHasInsurance: res.patientHasInsurance,
    }
  },
  userProfile(res: UserProfileResponse): UserProfile {
    const patients: Patient[] = res.patients.map((patient) => ({
      firstName: patient.first_name,
      lastName: patient.last_name,
      primary: patient.primary,
      truepillPatientToken: patient.truepill_patient_token,
    }))

    const patientRecord = patients.reduce((record, patient) => {
      record[patient.truepillPatientToken] = patient
      return record
    }, {} as Record<string, Patient>)

    const userProfile: UserProfile = {
      authorizedPatients: patientRecord,
    }
    return userProfile
  },
}
