import { Document } from '@contentful/rich-text-types'
import { Header, Spacer } from '@truepill/react-capsule'
import { VpTheme } from '@vpharm-platform/shared'

import { ThemedButton } from '../../../common/styledComponents/ThemedButton'
import RichTextRenderer from '../../../Components/RichTextRenderer'
import { StyledAutoRefillTermsCondition } from './styleComponents'

export interface AutoRefillTermsConditionModalProps {
  isOpen: boolean
  onDismiss: () => void
  enrollAutoRefill: () => Promise<void>
  termsAndCondition?: Document
  isSubmitting: boolean
  theme: VpTheme
}

const AutoRefillTermsConditionModal = ({
  isOpen,
  onDismiss,
  termsAndCondition,
  theme,
  enrollAutoRefill,
  isSubmitting,
}: AutoRefillTermsConditionModalProps): React.ReactElement => {
  return (
    <StyledAutoRefillTermsCondition.EnrollModal isOpen={isOpen} onDismiss={onDismiss}>
      <Header id='disenroll-modal-title' variant='2xl'>
        How automatic refills work
      </Header>
      <Spacer size='md' />
      {termsAndCondition ? (
        <RichTextRenderer openLinksInSameWindow={false} css={{ marginBottom: '10px' }} document={termsAndCondition} />
      ) : (
        'Unavailable'
      )}
      <StyledAutoRefillTermsCondition.ButtonContainer>
        <ThemedButton role='button' disabled={isSubmitting} onClick={enrollAutoRefill} vpTheme={theme}>
          Enable auto refill
        </ThemedButton>
      </StyledAutoRefillTermsCondition.ButtonContainer>
    </StyledAutoRefillTermsCondition.EnrollModal>
  )
}

export default AutoRefillTermsConditionModal
