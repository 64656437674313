import { Spacer, Text } from '@truepill/react-capsule'
import { VpTheme } from '@vpharm-platform/shared'
import { ReactElement, useCallback } from 'react'

import { ThemedButton } from '../../common/styledComponents/ThemedButton'
import { PatientInsurance } from '../../interfaces'
import LoadingAnimation from '../LoadingAnimation'
import { LoadingAnimationWrapper, StyledActionContainer, StyledHeader, Wrapper } from './styledComponents'

interface Props {
  theme: VpTheme
  currentInsurance: PatientInsurance
  isDeletingInsurance: boolean
  cancel: () => void
  handleDeleteInsurance: (insurance: PatientInsurance) => Promise<void>
}

const DeleteInsurance = ({ theme, currentInsurance, isDeletingInsurance, cancel, handleDeleteInsurance }: Props): ReactElement => {
  const handleCancel = useCallback(() => {
    cancel()
  }, [cancel])

  const handleDelete = useCallback(() => {
    handleDeleteInsurance(currentInsurance)
  }, [currentInsurance, handleDeleteInsurance])

  return (
    <>
      {isDeletingInsurance && (
        <LoadingAnimationWrapper vpTheme={theme}>
          <LoadingAnimation />
        </LoadingAnimationWrapper>
      )}
      <StyledHeader variant='4xl'>Are you sure you want to delete your insurance?</StyledHeader>
      <Spacer size='lg' />
      <Wrapper vpTheme={theme}>
        <Text bold>{`Cardholder Id: ${currentInsurance.cardholder_id}`}</Text>
        <Text>{`Rx BIN: ${currentInsurance.rx_bin}`}</Text>
        <Text>{`Rx PCN: ${currentInsurance.pcn}`}</Text>
        <Text>{`Rx Group: ${currentInsurance.rx_group}`}</Text>
      </Wrapper>
      <Spacer size='lg' />
      <Text css={{ color: theme.colors['typography-medium'] }}>
        Deleting this will permanently remove it from your account. You can always add this again later.
      </Text>
      <Spacer size='md' />
      <StyledActionContainer>
        <ThemedButton role='button' variant='dark-text' size='lg' onClick={handleCancel} disabled={isDeletingInsurance} vpTheme={theme}>
          Cancel
        </ThemedButton>
        <Spacer />
        <ThemedButton role='button' danger={true} size='lg' onClick={handleDelete} disabled={isDeletingInsurance} vpTheme={theme}>
          Yes, delete insurance
        </ThemedButton>
      </StyledActionContainer>
    </>
  )
}

export default DeleteInsurance
